import { extendTheme } from "@chakra-ui/react"

export default extendTheme({
  colors: {
    brand: {
      100: "#4B9C4A",
    },
  },
  components: {
    Button: { baseStyle: { _focus: { boxShadow: "none" } } },
    Tab: { baseStyle: { _focus: { boxShadow: "none" } } },
  },
  styles: {
    global: {
      body: {
        fontFamily:
          "Kaisei Tokumin, 游明朝, YuMincho, Hiragino Mincho ProN W3, ヒラギノ明朝 ProN W3, Hiragino Mincho ProN, HG明朝E, ＭＳ Ｐ明朝, ＭＳ 明朝, serif",
      },
    },
  },
})
